import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  DistributorHelper: () => import('../../components/Distributor/helper.js' /* webpackChunkName: "components/distributor-helper" */).then(c => wrapFunctional(c.default || c)),
  NotificationAuthorizationAdminPortal: () => import('../../components/NotificationAuthorizationAdminPortal/index.vue' /* webpackChunkName: "components/notification-authorization-admin-portal" */).then(c => wrapFunctional(c.default || c)),
  PageContent: () => import('../../components/PageContent/index.vue' /* webpackChunkName: "components/page-content" */).then(c => wrapFunctional(c.default || c)),
  PageFooter: () => import('../../components/PageFooter/index.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardHelper: () => import('../../components/SettingsWizard/helper.js' /* webpackChunkName: "components/settings-wizard-helper" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizard: () => import('../../components/SettingsWizard/index.vue' /* webpackChunkName: "components/settings-wizard" */).then(c => wrapFunctional(c.default || c)),
  ButtonsButtonDashed: () => import('../../components/Buttons/ButtonDashed/index.vue' /* webpackChunkName: "components/buttons-button-dashed" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorLibrary: () => import('../../components/Distributor/DistributorLibrary/index.vue' /* webpackChunkName: "components/distributor-distributor-library" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturersHelper: () => import('../../components/Distributor/DistributorManufacturers/helper.js' /* webpackChunkName: "components/distributor-distributor-manufacturers-helper" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturers: () => import('../../components/Distributor/DistributorManufacturers/index.vue' /* webpackChunkName: "components/distributor-distributor-manufacturers" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorPricesHelper: () => import('../../components/Distributor/DistributorPrices/helper.js' /* webpackChunkName: "components/distributor-distributor-prices-helper" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorPrices: () => import('../../components/Distributor/DistributorPrices/index.vue' /* webpackChunkName: "components/distributor-distributor-prices" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsHelper: () => import('../../components/Manufacturer/ManufacturerDistributors/helper.js' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-helper" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributors: () => import('../../components/Manufacturer/ManufacturerDistributors/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerLibraryEmpty: () => import('../../components/Manufacturer/ManufacturerLibraryEmpty/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-library-empty" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogHelper: () => import('../../components/Settings/SettingsCatalog/helper.js' /* webpackChunkName: "components/settings-settings-catalog-helper" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalog: () => import('../../components/Settings/SettingsCatalog/index.vue' /* webpackChunkName: "components/settings-settings-catalog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsHeader: () => import('../../components/Settings/SettingsHeader/index.vue' /* webpackChunkName: "components/settings-settings-header" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsPeople: () => import('../../components/Settings/SettingsPeople/index.vue' /* webpackChunkName: "components/settings-settings-people" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsTabContentContainer: () => import('../../components/Settings/SettingsTabContentContainer/index.vue' /* webpackChunkName: "components/settings-settings-tab-content-container" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardCustomSettingTab: () => import('../../components/SettingsWizard/CustomSettingTab/index.vue' /* webpackChunkName: "components/settings-wizard-custom-setting-tab" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardFormHeader: () => import('../../components/SettingsWizard/DistributorWizardFormHeader/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-form-header" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardGeneralInfoForm: () => import('../../components/SettingsWizard/DistributorWizardGeneralInfoForm/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-general-info-form" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardManageOrdersFormHelper: () => import('../../components/SettingsWizard/DistributorWizardManageOrdersForm/helper.js' /* webpackChunkName: "components/settings-wizard-distributor-wizard-manage-orders-form-helper" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardManageOrdersForm: () => import('../../components/SettingsWizard/DistributorWizardManageOrdersForm/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-manage-orders-form" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardManagePaymentsFormHelper: () => import('../../components/SettingsWizard/DistributorWizardManagePaymentsForm/helper.js' /* webpackChunkName: "components/settings-wizard-distributor-wizard-manage-payments-form-helper" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardManagePaymentsForm: () => import('../../components/SettingsWizard/DistributorWizardManagePaymentsForm/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-manage-payments-form" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardManageProcessesFormHelper: () => import('../../components/SettingsWizard/DistributorWizardManageProcessesForm/helper.js' /* webpackChunkName: "components/settings-wizard-distributor-wizard-manage-processes-form-helper" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardManageProcessesForm: () => import('../../components/SettingsWizard/DistributorWizardManageProcessesForm/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-manage-processes-form" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardPrivateLabelTab: () => import('../../components/SettingsWizard/DistributorWizardPrivateLabelTab/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-private-label-tab" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardShippingTermsForm: () => import('../../components/SettingsWizard/DistributorWizardShippingTermsForm/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-shipping-terms-form" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardManufacturerWizardGeneralInfoForm: () => import('../../components/SettingsWizard/ManufacturerWizardGeneralInfoForm/index.vue' /* webpackChunkName: "components/settings-wizard-manufacturer-wizard-general-info-form" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardManufacturerWizardSellDirectionTab: () => import('../../components/SettingsWizard/ManufacturerWizardSellDirectionTab/index.vue' /* webpackChunkName: "components/settings-wizard-manufacturer-wizard-sell-direction-tab" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardSettingsWizardDistributorActions: () => import('../../components/SettingsWizard/SettingsWizardDistributorActions/index.vue' /* webpackChunkName: "components/settings-wizard-settings-wizard-distributor-actions" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardSettingsWizardManufacturerActions: () => import('../../components/SettingsWizard/SettingsWizardManufacturerActions/index.vue' /* webpackChunkName: "components/settings-wizard-settings-wizard-manufacturer-actions" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardMixinsDistributorTab: () => import('../../components/SettingsWizard/mixins/distributorTab.js' /* webpackChunkName: "components/settings-wizard-mixins-distributor-tab" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardMixinsGeneralInfoTab: () => import('../../components/SettingsWizard/mixins/generalInfoTab.js' /* webpackChunkName: "components/settings-wizard-mixins-general-info-tab" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardMixinsManufacturerTab: () => import('../../components/SettingsWizard/mixins/manufacturerTab.js' /* webpackChunkName: "components/settings-wizard-mixins-manufacturer-tab" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardTab: () => import('../../components/SettingsWizard/mixins/wizardTab.js' /* webpackChunkName: "components/settings-wizard-tab" */).then(c => wrapFunctional(c.default || c)),
  OrderDetailsPracticeLocationInfo: () => import('../../components/order-details/PracticeLocationInfo/index.vue' /* webpackChunkName: "components/order-details-practice-location-info" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorLibraryDistributorLibraryActionsPanel: () => import('../../components/Distributor/DistributorLibrary/DistributorLibraryActionsPanel/index.vue' /* webpackChunkName: "components/distributor-distributor-library-distributor-library-actions-panel" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorLibraryDistributorLibraryEmpty: () => import('../../components/Distributor/DistributorLibrary/DistributorLibraryEmpty/index.vue' /* webpackChunkName: "components/distributor-distributor-library-distributor-library-empty" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorLibraryDistributorLibraryListHelper: () => import('../../components/Distributor/DistributorLibrary/DistributorLibraryList/helper.js' /* webpackChunkName: "components/distributor-distributor-library-distributor-library-list-helper" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorLibraryDistributorLibraryList: () => import('../../components/Distributor/DistributorLibrary/DistributorLibraryList/index.vue' /* webpackChunkName: "components/distributor-distributor-library-distributor-library-list" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorLibraryDistributorLibraryListHeader: () => import('../../components/Distributor/DistributorLibrary/DistributorLibraryListHeader/index.vue' /* webpackChunkName: "components/distributor-distributor-library-distributor-library-list-header" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturersDistributorManufacturersHeader: () => import('../../components/Distributor/DistributorManufacturers/DistributorManufacturersHeader/index.vue' /* webpackChunkName: "components/distributor-distributor-manufacturers-distributor-manufacturers-header" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturersDistributorManufacturersList: () => import('../../components/Distributor/DistributorManufacturers/DistributorManufacturersList/index.vue' /* webpackChunkName: "components/distributor-distributor-manufacturers-distributor-manufacturers-list" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorPricesDistributorPricesActionsPanel: () => import('../../components/Distributor/DistributorPrices/DistributorPricesActionsPanel/index.vue' /* webpackChunkName: "components/distributor-distributor-prices-distributor-prices-actions-panel" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorPricesDistributorPricesListHelper: () => import('../../components/Distributor/DistributorPrices/DistributorPricesList/helper.js' /* webpackChunkName: "components/distributor-distributor-prices-distributor-prices-list-helper" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorPricesDistributorPricesList: () => import('../../components/Distributor/DistributorPrices/DistributorPricesList/index.vue' /* webpackChunkName: "components/distributor-distributor-prices-distributor-prices-list" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorPricesDistributorPricesListHeader: () => import('../../components/Distributor/DistributorPrices/DistributorPricesListHeader/index.vue' /* webpackChunkName: "components/distributor-distributor-prices-distributor-prices-list-header" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsManufacturerDistributorsHeader: () => import('../../components/Manufacturer/ManufacturerDistributors/ManufacturerDistributorsHeader/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-manufacturer-distributors-header" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsManufacturerDistributorsList: () => import('../../components/Manufacturer/ManufacturerDistributors/ManufacturerDistributorsList/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-manufacturer-distributors-list" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsManufacturerDistributorsSelfRow: () => import('../../components/Manufacturer/ManufacturerDistributors/ManufacturerDistributorsSelfRow/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-manufacturer-distributors-self-row" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogCategoryAddButton: () => import('../../components/Settings/SettingsCatalog/CategoryAddButton/index.vue' /* webpackChunkName: "components/settings-settings-catalog-category-add-button" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericList: () => import('../../components/Settings/SettingsCatalog/GenericList/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogMixinsEditItem: () => import('../../components/Settings/SettingsCatalog/mixins/editItem.js' /* webpackChunkName: "components/settings-settings-catalog-mixins-edit-item" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogMixinsResources: () => import('../../components/Settings/SettingsCatalog/mixins/resources.js' /* webpackChunkName: "components/settings-settings-catalog-mixins-resources" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardFormHeaderDistributorWizardFormHeaderTooltipListHelper: () => import('../../components/SettingsWizard/DistributorWizardFormHeader/DistributorWizardFormHeaderTooltipList/helper.js' /* webpackChunkName: "components/settings-wizard-distributor-wizard-form-header-distributor-wizard-form-header-tooltip-list-helper" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardFormHeaderDistributorWizardFormHeaderTooltipList: () => import('../../components/SettingsWizard/DistributorWizardFormHeader/DistributorWizardFormHeaderTooltipList/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-form-header-distributor-wizard-form-header-tooltip-list" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardGeneralInfoFormAddDistributorFormUploadLogo: () => import('../../components/SettingsWizard/DistributorWizardGeneralInfoForm/AddDistributorFormUploadLogo/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-general-info-form-add-distributor-form-upload-logo" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardGeneralInfoFormAddDistributorFormUploadLogoFile: () => import('../../components/SettingsWizard/DistributorWizardGeneralInfoForm/AddDistributorFormUploadLogo/uploadLogoFile.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-general-info-form-add-distributor-form-upload-logo-file" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardGeneralInfoFormAddDistributorFormUploadLogoSkeleton: () => import('../../components/SettingsWizard/DistributorWizardGeneralInfoForm/AddDistributorFormUploadLogo/uploadLogoSkeleton.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-general-info-form-add-distributor-form-upload-logo-skeleton" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardGeneralInfoFormAddressInputSearchGmaps: () => import('../../components/SettingsWizard/DistributorWizardGeneralInfoForm/AddressInputSearchGmaps/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-general-info-form-address-input-search-gmaps" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardGeneralInfoFormMap: () => import('../../components/SettingsWizard/DistributorWizardGeneralInfoForm/Map/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-general-info-form-map" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardGeneralInfoFormMapSearchWithLink: () => import('../../components/SettingsWizard/DistributorWizardGeneralInfoForm/MapSearchWithLink/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-general-info-form-map-search-with-link" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardManufacturerWizardGeneralInfoFormMap: () => import('../../components/SettingsWizard/ManufacturerWizardGeneralInfoForm/Map/index.vue' /* webpackChunkName: "components/settings-wizard-manufacturer-wizard-general-info-form-map" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardManufacturerWizardGeneralInfoFormMapSearch: () => import('../../components/SettingsWizard/ManufacturerWizardGeneralInfoForm/MapSearch/index.vue' /* webpackChunkName: "components/settings-wizard-manufacturer-wizard-general-info-form-map-search" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorLibraryDistributorLibraryListDistributorLibraryListRow: () => import('../../components/Distributor/DistributorLibrary/DistributorLibraryList/DistributorLibraryListRow/index.vue' /* webpackChunkName: "components/distributor-distributor-library-distributor-library-list-distributor-library-list-row" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturersDistributorManufacturersListDistributorManufacturersListHeader: () => import('../../components/Distributor/DistributorManufacturers/DistributorManufacturersList/DistributorManufacturersListHeader/index.vue' /* webpackChunkName: "components/distributor-distributor-manufacturers-distributor-manufacturers-list-distributor-manufacturers-list-header" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturersDistributorManufacturersListDistributorManufacturersListRow: () => import('../../components/Distributor/DistributorManufacturers/DistributorManufacturersList/DistributorManufacturersListRow/index.vue' /* webpackChunkName: "components/distributor-distributor-manufacturers-distributor-manufacturers-list-distributor-manufacturers-list-row" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorPricesDistributorPricesListDistributorPricesListRow: () => import('../../components/Distributor/DistributorPrices/DistributorPricesList/DistributorPricesListRow/index.vue' /* webpackChunkName: "components/distributor-distributor-prices-distributor-prices-list-distributor-prices-list-row" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsManufacturerDistributorsListManufacturerDistributorsListHeader: () => import('../../components/Manufacturer/ManufacturerDistributors/ManufacturerDistributorsList/ManufacturerDistributorsListHeader/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-manufacturer-distributors-list-manufacturer-distributors-list-header" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsManufacturerDistributorsListManufacturerDistributorsListRow: () => import('../../components/Manufacturer/ManufacturerDistributors/ManufacturerDistributorsList/ManufacturerDistributorsListRow/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-manufacturer-distributors-list-manufacturer-distributors-list-row" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsAttributeAddDialog: () => import('../../components/Settings/SettingsCatalog/Dialogs/AttributeAddDialog/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-attribute-add-dialog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsAttributeEditDialog: () => import('../../components/Settings/SettingsCatalog/Dialogs/AttributeEditDialog/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-attribute-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsCategoryAddDialog: () => import('../../components/Settings/SettingsCatalog/Dialogs/CategoryAddDialog/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-category-add-dialog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsCategoryEditDialog: () => import('../../components/Settings/SettingsCatalog/Dialogs/CategoryEditDialog/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-category-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsDeleteAttributeDialog: () => import('../../components/Settings/SettingsCatalog/Dialogs/DeleteAttributeDialog/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-delete-attribute-dialog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsGenericNameEditDialog: () => import('../../components/Settings/SettingsCatalog/Dialogs/GenericNameEditDialog/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-generic-name-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsSubcategoryEditDialog: () => import('../../components/Settings/SettingsCatalog/Dialogs/SubcategoryEditDialog/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-subcategory-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsValueEditDialog: () => import('../../components/Settings/SettingsCatalog/Dialogs/ValueEditDialog/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-value-edit-dialog" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogFiltersFilterAttributeValue: () => import('../../components/Settings/SettingsCatalog/Filters/FilterAttributeValue/index.vue' /* webpackChunkName: "components/settings-settings-catalog-filters-filter-attribute-value" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogFiltersFilterSds: () => import('../../components/Settings/SettingsCatalog/Filters/FilterSds/index.vue' /* webpackChunkName: "components/settings-settings-catalog-filters-filter-sds" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListHeader: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListHeader/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-header" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRow: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsPeopleHelpersAccessLevel: () => import('../../components/Settings/SettingsPeople/helpers/access-level/index.vue' /* webpackChunkName: "components/settings-settings-people-helpers-access-level" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsPeopleHelpersAscDescSort: () => import('../../components/Settings/SettingsPeople/helpers/ascDescSort/index.vue' /* webpackChunkName: "components/settings-settings-people-helpers-asc-desc-sort" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsPeopleHelpersPeopleItem: () => import('../../components/Settings/SettingsPeople/helpers/people-item/index.vue' /* webpackChunkName: "components/settings-settings-people-helpers-people-item" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsPeopleHelpersTabMenu: () => import('../../components/Settings/SettingsPeople/helpers/tab-menu/index.vue' /* webpackChunkName: "components/settings-settings-people-helpers-tab-menu" */).then(c => wrapFunctional(c.default || c)),
  SettingsWizardDistributorWizardFormHeaderDistributorWizardFormHeaderTooltipListDistributorWizardFormHeaderTooltipItem: () => import('../../components/SettingsWizard/DistributorWizardFormHeader/DistributorWizardFormHeaderTooltipList/DistributorWizardFormHeaderTooltipItem/index.vue' /* webpackChunkName: "components/settings-wizard-distributor-wizard-form-header-distributor-wizard-form-header-tooltip-list-distributor-wizard-form-header-tooltip-item" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorLibraryDistributorLibraryListDistributorLibraryListRowStatusMenu: () => import('../../components/Distributor/DistributorLibrary/DistributorLibraryList/DistributorLibraryListRow/StatusMenu/index.vue' /* webpackChunkName: "components/distributor-distributor-library-distributor-library-list-distributor-library-list-row-status-menu" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturersDistributorManufacturersListDistributorManufacturersListHeaderFilterDistributorsAuthorized: () => import('../../components/Distributor/DistributorManufacturers/DistributorManufacturersList/DistributorManufacturersListHeader/FilterDistributorsAuthorized/index.vue' /* webpackChunkName: "components/distributor-distributor-manufacturers-distributor-manufacturers-list-distributor-manufacturers-list-header-filter-distributors-authorized" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturersDistributorManufacturersListDistributorManufacturersListHeaderFilterIcon: () => import('../../components/Distributor/DistributorManufacturers/DistributorManufacturersList/DistributorManufacturersListHeader/filter-icon/index.vue' /* webpackChunkName: "components/distributor-distributor-manufacturers-distributor-manufacturers-list-distributor-manufacturers-list-header-filter-icon" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorPricesDistributorPricesListDistributorPricesListRowStatusMenu: () => import('../../components/Distributor/DistributorPrices/DistributorPricesList/DistributorPricesListRow/StatusMenu/index.vue' /* webpackChunkName: "components/distributor-distributor-prices-distributor-prices-list-distributor-prices-list-row-status-menu" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsManufacturerDistributorsListManufacturerDistributorsListHeaderFilterDistributorsAuthorized: () => import('../../components/Manufacturer/ManufacturerDistributors/ManufacturerDistributorsList/ManufacturerDistributorsListHeader/FilterDistributorsAuthorized/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-manufacturer-distributors-list-manufacturer-distributors-list-header-filter-distributors-authorized" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsManufacturerDistributorsListManufacturerDistributorsListHeaderFilterIcon: () => import('../../components/Manufacturer/ManufacturerDistributors/ManufacturerDistributorsList/ManufacturerDistributorsListHeader/filter-icon/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-manufacturer-distributors-list-manufacturer-distributors-list-header-filter-icon" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsAttributeAddDialogAttributeFooter: () => import('../../components/Settings/SettingsCatalog/Dialogs/AttributeAddDialog/attribute-footer/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-attribute-add-dialog-attribute-footer" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsAttributeAddDialogAttributeForm: () => import('../../components/Settings/SettingsCatalog/Dialogs/AttributeAddDialog/attribute-form/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-attribute-add-dialog-attribute-form" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsAttributeAddDialogAttributeHead: () => import('../../components/Settings/SettingsCatalog/Dialogs/AttributeAddDialog/attribute-head/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-attribute-add-dialog-attribute-head" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsAttributeAddDialogAttributeValuesForm: () => import('../../components/Settings/SettingsCatalog/Dialogs/AttributeAddDialog/attribute-values-form/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-attribute-add-dialog-attribute-values-form" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsCategoryAddDialogCategoryFooter: () => import('../../components/Settings/SettingsCatalog/Dialogs/CategoryAddDialog/category-footer/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-category-add-dialog-category-footer" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsCategoryAddDialogCategoryHead: () => import('../../components/Settings/SettingsCatalog/Dialogs/CategoryAddDialog/category-head/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-category-add-dialog-category-head" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsCategoryAddDialogCategorySelect: () => import('../../components/Settings/SettingsCatalog/Dialogs/CategoryAddDialog/category-select/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-category-add-dialog-category-select" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsCategoryAddDialogNewNameCategory: () => import('../../components/Settings/SettingsCatalog/Dialogs/CategoryAddDialog/new-name-category/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-category-add-dialog-new-name-category" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogDialogsCategoryAddDialogNewSubCategoryGeneric: () => import('../../components/Settings/SettingsCatalog/Dialogs/CategoryAddDialog/new-sub-category-generic/index.vue' /* webpackChunkName: "components/settings-settings-catalog-dialogs-category-add-dialog-new-sub-category-generic" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogFiltersFilterAttributeValueFilterAttributeValueViewHelper: () => import('../../components/Settings/SettingsCatalog/Filters/FilterAttributeValue/FilterAttributeValueView/helper.js' /* webpackChunkName: "components/settings-settings-catalog-filters-filter-attribute-value-filter-attribute-value-view-helper" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogFiltersFilterAttributeValueFilterAttributeValueView: () => import('../../components/Settings/SettingsCatalog/Filters/FilterAttributeValue/FilterAttributeValueView/index.vue' /* webpackChunkName: "components/settings-settings-catalog-filters-filter-attribute-value-filter-attribute-value-view" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogFiltersFilterSdsFilterSdsView: () => import('../../components/Settings/SettingsCatalog/Filters/FilterSds/FilterSdsView/index.vue' /* webpackChunkName: "components/settings-settings-catalog-filters-filter-sds-filter-sds-view" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsPeopleHelpersModalCreateZenRep: () => import('../../components/Settings/SettingsPeople/helpers/modal/create-zen-rep/index.vue' /* webpackChunkName: "components/settings-settings-people-helpers-modal-create-zen-rep" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsPeopleHelpersModalEditEmail: () => import('../../components/Settings/SettingsPeople/helpers/modal/edit-email/index.vue' /* webpackChunkName: "components/settings-settings-people-helpers-modal-edit-email" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsPeopleHelpersModalEditUserName: () => import('../../components/Settings/SettingsPeople/helpers/modal/edit-user-name/index.vue' /* webpackChunkName: "components/settings-settings-people-helpers-modal-edit-user-name" */).then(c => wrapFunctional(c.default || c)),
  DistributorDistributorManufacturersDistributorManufacturersListDistributorManufacturersListHeaderFilterDistributorsAuthorizedFilterAuthorized: () => import('../../components/Distributor/DistributorManufacturers/DistributorManufacturersList/DistributorManufacturersListHeader/FilterDistributorsAuthorized/FilterAuthorized/index.vue' /* webpackChunkName: "components/distributor-distributor-manufacturers-distributor-manufacturers-list-distributor-manufacturers-list-header-filter-distributors-authorized-filter-authorized" */).then(c => wrapFunctional(c.default || c)),
  ManufacturerManufacturerDistributorsManufacturerDistributorsListManufacturerDistributorsListHeaderFilterDistributorsAuthorizedFilterAuthorized: () => import('../../components/Manufacturer/ManufacturerDistributors/ManufacturerDistributorsList/ManufacturerDistributorsListHeader/FilterDistributorsAuthorized/FilterAuthorized/index.vue' /* webpackChunkName: "components/manufacturer-manufacturer-distributors-manufacturer-distributors-list-manufacturer-distributors-list-header-filter-distributors-authorized-filter-authorized" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogFiltersFilterAttributeValueFilterAttributeValueViewTreeviewRow: () => import('../../components/Settings/SettingsCatalog/Filters/FilterAttributeValue/FilterAttributeValueView/TreeviewRow/index.vue' /* webpackChunkName: "components/settings-settings-catalog-filters-filter-attribute-value-filter-attribute-value-view-treeview-row" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRowGenericListRowDataCategoryName: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/GenericListRowData/CategoryName/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row-generic-list-row-data-category-name" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRowGenericListRowDataEditBtn: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/GenericListRowData/EditBtn/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row-generic-list-row-data-edit-btn" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRowGenericListRowDataGenericName: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/GenericListRowData/GenericName/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row-generic-list-row-data-generic-name" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRowGenericListRowDataModifierList: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/GenericListRowData/ModifierList/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row-generic-list-row-data-modifier-list" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRowGenericListRowDataSdsSwitcher: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/GenericListRowData/SdsSwitcher/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row-generic-list-row-data-sds-switcher" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRowGenericListRowDataSubcategoryName: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/GenericListRowData/SubcategoryName/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row-generic-list-row-data-subcategory-name" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRowGenericListRowDataModifierListModifierListRow: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/GenericListRowData/ModifierList/ModifierListRow/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row-generic-list-row-data-modifier-list-modifier-list-row" */).then(c => wrapFunctional(c.default || c)),
  SettingsSettingsCatalogGenericListGenericListRowGenericListRowDataModifierListModifierListRowModifierName: () => import('../../components/Settings/SettingsCatalog/GenericList/GenericListRow/GenericListRowData/ModifierList/ModifierListRow/ModifierName/index.vue' /* webpackChunkName: "components/settings-settings-catalog-generic-list-generic-list-row-generic-list-row-data-modifier-list-modifier-list-row-modifier-name" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
